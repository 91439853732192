import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

// Styles
import "./App.css"

import NavigationBar from "./features/NavigationBar/NavigationBar"

// Redux

// Screens
// import Ads from "./app/Screens/Ads/Ads"
// import Analytics from "./app/Screens/Analytics"
// import Dashboard from './app/Screens/Dashboard/Dashboard'
// import MediaManager from "./app/Screens/MediaManager/MediaManager"
// import Playlists from "./app/Screens/Playlists/Playlists"
import ScreenManager from "./app/Screens/ScreenManager/ScreenManager"
// import Screensavers from "./app/Screens/Screensavers"
// import Settings from "./app/Screens/Settings/Settings"
import Login from "./app/Screens/Login/Login"
import ScreenDetails from "./app/Screens/ScreenDetails/ScreenDetails"
import Feeds from "./app/Screens/Feeds/Feeds"

function App() {
  return (
    <>
      <Router>
        {/* {(localStorage.token === "Bearer undefined" ||
          localStorage.token === undefined) && <Login />} */}

        <Route exact path="/" component={Login} />

        <div className="App">
          <div className="Navigation">
            <NavigationBar />
          </div>

          <div className="body">
            <Switch>
              <>
                <Route path="/dashboard" component={ScreenManager} />
                <Route path="/screen/:pknum" component={ScreenDetails} />
                <Route path="/feeds" component={Feeds} />
              </>
            </Switch>
          </div>
        </div>
      </Router>
    </>
  )
}

export default App
