import React from 'react'

// Styles
import styles from './DashboardWelcome.module.scss'

// Icons
import ErrorIcon from '@material-ui/icons/Error'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'

export default function DashboardWelcome ({attention, fiveMins, thirtyMins, grabAttention, grabFive, grabThirty}) {
  return (
    <>
        { attention > 0 && 
      <div className={styles.welcomeInfo}>
        <div className={styles.iconContainer}>
          <ErrorIcon style={{ color: '#FF1053' }} />
        </div>
        You have &nbsp;<b>{attention}</b> &nbsp;screens that need attention&nbsp;
      </div>
       }

      <div className={styles.welcomeInfo}>
        <div className={styles.iconContainer}>
          <QueryBuilderIcon style={{ color: '#FE9000' }} />
        </div>
        You have &nbsp;<b>{fiveMins}</b> &nbsp; screens that haven't been seen for more than 10 minutes&nbsp;
      </div>


      <div className={styles.welcomeInfo}>
        <div className={styles.iconContainer}>
          <QueryBuilderIcon style={{ color: '#FF1053' }} />
        </div>
        You have &nbsp;<b>{thirtyMins}</b> &nbsp; screens that haven't been seen for more than an hour&nbsp;
      </div>
    </>

  )
}
